import React, { useEffect } from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation } from "react-router-dom";

import conditionalClassName from "../../../../utility/conditionalClassName";

function AppMobileMenu({ className }) {
  const [open, setOpen] = React.useState(false);
  const navClassName = clsx(
    "styles-nav",
    "hidden fixed top-[4rem] left-[0] right-[0] bg-[#d0d5da] [box-shadow:0_0_1rem_rgba(34,_34,_34,_0.4)] z-[7]",
    open && clsx("styles-open", "!block")
  );
  const { pathname } = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  const itemClassName = ({ isActive }) =>
    clsx(
      "styles-item",
      "block p-4 border-b-[1px_solid_#596a7d] no-underline [&:last-child]:border-b-[none]",
      isActive && "styles-active font-bold bg-[#0ca1e6] text-[#fff]"
    );

  return (
    <div
      className={clsx(
        "styles-menu",
        "sm:hidden",
        conditionalClassName(className)
      )}
    >
      <button
        className="text-[#fff] text-[1.8rem] pl-16 pr-2 py-2"
        onClick={() => setOpen(!open)}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </button>
      <nav className={clsx(navClassName)}>
        <ul className="[list-style:none] m-0 p-0">
          <li>
            <NavLink className={itemClassName} to="/">
              Zur Einführung
            </NavLink>
          </li>
          <li>
            <NavLink className={itemClassName} to="/analyse">
              Analyse
            </NavLink>
          </li>
          <li>
            <NavLink className={itemClassName} to="/report">
              Report
            </NavLink>
          </li>
          <li>
            <NavLink className={itemClassName} to="/katalog">
              Katalog
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default AppMobileMenu;
