import React, { useEffect } from "react";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import AnalysisProgressBar from "./AnalysisProgressBar";
import { progressSelector, surveySelector } from "../../../../state/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faClose } from "@fortawesome/free-solid-svg-icons/faClose";
import clsx from "clsx";

// todo: switch to full progress bar on when space is not enough (mobile)
function AnalysisTabs() {
  const survey = useSelector(surveySelector);
  const progress = useSelector(progressSelector);
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  useEffect(() => setOpen(false), [location]);

  const tabClassName = ({ isActive }) =>
    clsx(
      "styles-tab",
      "mt-auto",
      isActive && "styles-active !bg-white !font-semibold",
      isActive &&
        "!bg-[linear-gradient(90deg,_rgba(0,_144,_170,_100),_rgba(0,_144,_170,_100)_var(--progress),_#fff_0)]",
      "content-[attr(title)] block lg:h-8 overflow-hidden select-none relative h-8 lg:leading-8 bg-[#d0d5da] lg:text-[0.85rem] px-[1.5rem] lg:px-2 py-[0] lg:text-center no-underline",
      "text-left text-[1.2rem] leading-[3.5rem] h-auto border-b-[1px_solid_#596a7d] lg:border-b-0 bg-[linear-gradient(90deg,_rgba(0,_144,_170,_100),_rgba(0,_144,_170,_100)_var(--progress),_#596a7d-light_0)] lg:bg-none bg-no-repeat [background-size:100%_2px] bg-[0_100%]"
    );

  const currentCategory = survey.find(
    (category) => !!matchPath(`/analyse/${category.slug}`, location.pathname)
  );

  return (
    <nav
      className={clsx(
        "styles-wrapper",
        "block lg:flex justify-center h-full",
        "[&_li_+_li]:mt-0"
      )}
    >
      <AnalysisProgressBar
        className={clsx("styles-totalProgress", "lg:hidden h-6 leading-6")}
      />
      <button
        className={clsx(
          "styles-title",
          open && "styles-open",
          "pl-8 pr-8 relative lg:hidden w-full h-14 leading-[3.5rem] text-[1.2rem] text-left bg-[#367b8d] text-[#fff]",
          "[&_svg]:absolute [&_svg]:top-4 [&_svg]:right-4 [&_svg]:text-[1.5rem] [&_svg]:text-[#fff]"
        )}
        onClick={() => setOpen(!open)}
      >
        <span>
          {currentCategory
            ? `${currentCategory.module}. ${
                currentCategory.navigationTitle ?? currentCategory?.title
              }`
            : ""}
        </span>
        <FontAwesomeIcon icon={open ? faClose : faBars} />
      </button>
      <ol
        className={clsx(
          "styles-tabs",
          open && "styles-open !block",
          "lg:px-4 lg:py-[0] [list-style:none] m-0 lg:flex max-w-[1200px] w-full hidden flex-col lg:flex-row p-0 [box-shadow:0_0_1rem_rgba(34,_34,_34,_0.4)] lg:shadow-none"
        )}
      >
        {survey.map((category, index) => {
          const label = `${index + 1}. ${
            category.navigationTitle ?? category.title
          }`;
          const path = `/analyse/${category.slug}`;
          const percent = progress.byCategory[category.id] ?? 0;
          return (
            <li
              key={index}
              style={{ "--progress": `${percent}%` }}
              className={clsx(
                !!location.pathname.includes(path)
                  ? "styles-active bg-white lg:bg-transparent"
                  : "",
                "flex flex-col flex-grow overflow-hidden pr-[0.1rem]",
                "[&:first-child_.styles-progress]:rounded-l-2xl lg:[&:first-child_.styles-tab]:rounded-tl-2xl",
                "[&:last-child]:pr-0",
                "[&:last-child_.styles-progress]:rounded-r-2xl lg:[&:last-child_.styles-tab]:rounded-tr-2xl"
              )}
            >
              <AnalysisProgressBar
                className={clsx(
                  "styles-progress",
                  "hidden lg:block overflow-hidden"
                )}
                category={category}
              />
              <NavLink className={tabClassName} title={label} to={path}>
                {label}
                <span
                  className={clsx(
                    "styles-inlineProgress",
                    "lg:hidden text-[0.9rem] float-right"
                  )}
                >
                  {percent}%
                </span>
              </NavLink>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

export default AnalysisTabs;
