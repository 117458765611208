import React, { useEffect, useState } from "react";
import PageIntro from "../../Elements/Content/PageIntro";
import { useNavigate } from "react-router-dom";
import ProcessNavigation from "../../Elements/Navigation/ProcessNavigation";
import { useSelector } from "react-redux";
import ReportChartDebugger from "./ReportChartDebugger";
import Tile from "../../Elements/Content/Tile";
import ReportChart from "./ReportChart";
import AnalyzerService from "../../../services/AnalyzerService";
import {
  answersSelector,
  progressSelector,
  surveySelector,
} from "../../../state/selectors";
import ReportChartLegend from "./ReportChartLegend";
import VideoTeaser from "../../Elements/Content/VideoTeaser";
import clsx from "clsx";

/*
 * todo: create two column layout
 * todo: add video
 * todo: new component: create report svg graphic with named elements
 * todo: implement report
 * todo: add hint for missing input
 */
function Report() {
  const survey = useSelector(surveySelector);
  const answers = useSelector(answersSelector);
  const progress = useSelector(progressSelector);
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);

  const lastSurveyPage = `/analyse/${survey[survey.length - 1]?.slug ?? ""}`;
  const navigation = (
    <ProcessNavigation
      onNext={() => navigate("/katalog")}
      onPrevious={() => navigate(lastSurveyPage)}
    />
  );

  // todo: move to reducer when answer is updated
  useEffect(() => {
    setReports(
      survey.map((category) => AnalyzerService.createReport(category, answers))
    );
  }, [survey, answers]);

  return (
    <>
      <section
        className={clsx(
          "styles-report",
          'grid lg:grid-cols-[30rem_1fr] lg:grid-rows-[1fr_auto] lg:[grid-template-areas:"intro_chart"_"nav_chart"] gap-x-[2rem]',
          'grid-cols-[1fr] grid-rows-[auto_auto_auto] [grid-template-areas:"intro"_"chart"_"nav"]'
        )}
      >
        <PageIntro title={`Welche KI-Anwendungen sind für Sie interessant? `}>
          <p>
            Wie KI im Technischen Service unterstützen kann, bringt Ihnen der
            von Service-Meister entwickelte Avatar „Gloria“ im Video näher.
            Durch ihn erfahren Sie anschaulich und Schritt für Schritt am
            Servicelifecycle orientiert, wie ein KI-basiertes Ökosystem ins
            Unternehmen eingeführt wird und wie sich firmeninternes
            Servicewissen besser nutzen und vermarkten lässt.
          </p>
          <VideoTeaser embedId="N-WNHjihPpk" />
        </PageIntro>
        <Tile
          className={clsx(
            "styles.navigation",
            "[grid-area:nav] -mt-8",
            progress.total < 100 && "styles-notice mt-8"
          )}
          type={progress.total < 100 ? "NOTICE" : "DEFAULT"}
        >
          {progress.total < 100 ? (
            <>
              <p>
                Ihre Angaben sind unvollständig. Um genauere Ergbenisse zu
                erhalten, können Sie versuchen, alle Fragen zu beantworten.
              </p>
              <p>Andernfalls können sie direkt weiter zum Katalog gehen.</p>
            </>
          ) : null}
          {navigation}
        </Tile>
        <div
          className={clsx(
            "styles-chart",
            "[grid-area:chart] grid grid-cols-[1fr] grid-rows-[repeat(2,_min-content)] gap-[2rem]"
          )}
        >
          <ReportChart reports={reports} />
          <ReportChartLegend />
        </div>
      </section>

      <ReportChartDebugger />
    </>
  );
}

export default Report;
