import React from "react";
import Table from "../../../Elements/Content/Table";

const columnHeaders = new Map([
  ["input-data", "Inputdata"],
  ["preprocessing", "Preprocessing"],
  ["data-storage", "Datenspeicher"],
  ["algorithms", "Algorithmen"],
  ["interfaces", "Interfaces"],
]);

const rowHeaders = new Map([
  ["high-level-description", "High-Level Beschreibung"],
  ["configurability", "Konfigurierbarkeit"],
  ["technical-implementation", "Technische Umsetzung"],
  ["specific-example", "Spezifisches Beispiel aus dem Schnellboot"],
]);

export default function ServiceSectionContent({ section }) {
  switch (section.type) {
    case "text":
      return (
        <>
          {section.content.map((content, index) => (
            <p key={index}>{content}</p>
          ))}
        </>
      );
    case "list":
      return (
        <ul className="list-disc">
          {section.content.map((content, index) => (
            <li key={index}>{content}</li>
          ))}
        </ul>
      );
    case "table":
      return (
        <Table
          columnHeaders={columnHeaders}
          rowHeaders={rowHeaders}
          rawRows={section.content}
        />
      );
    default:
      return <div></div>;
  }
}
