import React from "react";

function Button({ children, className, ghost, ...props }) {
  className = "btn" + (className ? " " + className : "");
  className = className + (ghost ? " btn--ghost" : "");
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
}

export default Button;
