import React from "react";
import { ReactComponent as Flower } from "../../../../assets/images/report-flower.svg";
import conditionalClassName from "../../../../utility/conditionalClassName";
import clsx from "clsx";

function ReportFlower({ module, result, className }) {
  return (
    <div
      className={clsx(
        "styles-reportFlower",
        "report-flower",
        "relative block w-16 h-16",
        conditionalClassName(className)
      )}
      data-module={module}
    >
      <Flower
        className="absolute top-[0] left-[0] w-16 h-16 before:content-[attr(data-module)] before:absolute before:left-0 before:top-0 before:flex before:items-center before:justify-center before:text-[#fff] before:font-bold before:w-16 before:h-16 before:text-[1rem] before:text-center before:align-baseline"
        style={{
          [`--result-modul-${module}`]: `var(--result-color-${result
            .toLowerCase()
            .replace("_", "-")})`,
        }}
      />
    </div>
  );
}

export default ReportFlower;
