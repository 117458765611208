import "./main.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store, { persistor } from "./state/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<p>loading</p>}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
