import React from "react";
import Tile from "./Tile";

function PageIntro({ rootClassName, title, action, children }) {
  return (
    <Tile
      rootClassName={rootClassName}
      header={<h1>{title}</h1>}
      action={action}
    >
      {children}
    </Tile>
  );
}

export default PageIntro;
