import React from "react";
import { createServiceByCategorySelector } from "../../../../state/selectors";
import CatalogService from "../CatalogService";
import ModuleTeaser from "../../../Elements/Content/ModuleTeaser";
import { useSelector } from "react-redux";
import clsx from "clsx";

function CatalogCategory({ category }) {
  const documents = useSelector(createServiceByCategorySelector(category));

  return (
    <section
      className={clsx(
        "styles-category",
        "relative grid grid-cols-[1fr] -ml-6 sm:ml-0 -mr-6 sm:mr-0 sm:grid-cols-[22rem_1fr] lg:grid-cols-[23rem_1fr] mb-8 bg-[#c5dadf]"
      )}
    >
      <ModuleTeaser category={category} />
      <ul
        className={clsx(
          "styles-services",
          "[list-style:none] m-0 p-0 bg-[#d6e5e8] text-[0.9rem]",
          "&:global(.btn):text-[0.8rem] &:global(.btn):px-4 &:global(.btn):py-2"
        )}
      >
        {documents.map((service, index) => (
          <CatalogService key={index} service={service} />
        ))}
      </ul>
    </section>
  );
}

export default CatalogCategory;
