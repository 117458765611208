import AnalysisChildren from "../AnalysisChildren";
import AnalysisStatementHeader from "../AnalysisStatementHeader/AnalysisStatementHeader";

function AnalysisSubCategory({ questionSlug, category }) {
  return (
    <section className={"styles-subCategory"}>
      <AnalysisStatementHeader title={category.title} />
      <AnalysisChildren
        questionSlug={questionSlug}
        categoryChildren={category.children}
      />
    </section>
  );
}

export default AnalysisSubCategory;
