import { useState } from "react";
import clsx from "clsx";

function Tile({
  rootClassName,
  className,
  children,
  header,
  headerBleed,
  action,
  type,
  tabs,
  activeTab,
}) {
  if (tabs && tabs.length > 0 && !activeTab) {
    activeTab = tabs[0].key;
  }

  const [activeTabKey, setActiveTabKey] = useState(activeTab);
  const classNames = determineClassNames(
    className,
    tabs,
    action,
    headerBleed,
    type
  );

  return (
    <div className={clsx(classNames.tile, rootClassName)}>
      {tabs ? (
        <TabNavigation
          tabs={tabs}
          activeTabKey={activeTabKey}
          onClick={setActiveTabKey}
        />
      ) : null}
      {header ? <div className={classNames.header}>{header}</div> : null}
      <div className={classNames.content}>
        {tabs ? (
          <TabsContent tabs={tabs} activeTabKey={activeTabKey} />
        ) : (
          children
        )}
      </div>
      {action ? <div className={classNames.action}>{action}</div> : null}
    </div>
  );
}

export default Tile;

function TabNavigation({ tabs, activeTabKey, onClick }) {
  return (
    <nav
      className={clsx(
        "styles.tabsNavigation",
        "absolute -top-10 left-[0] flex justify-center"
      )}
    >
      {tabs.map((tab) => {
        return (
          <button
            key={tab.key}
            onClick={() => onClick(tab.key)}
            className={clsx(
              "styles.tab",
              "content-[attr(title)] block font-medium overflow-hidden select-none relative h-10 leading-8 bg-[#d0d5da] text-[#3c424f] text-[1rem] px-[1.2em] py-[0] text-center no-underline mt-auto mr-[0.1rem] cursor-pointer first-child:rounded-tl-2xl last-child:rounded-tr-2xl last-child:mr-0",
              "[&:first-child]:rounded-tl-[1rem] [&:last-child]:rounded-tr-[1rem]",
              tab.key === activeTabKey && "!bg-[#EEEEEE] font-bold"
            )}
          >
            {tab.label}
          </button>
        );
      })}
    </nav>
  );
}

function TabsContent({ tabs, activeTabKey }) {
  return (
    <div className={clsx("styles.tabs")}>
      {tabs.map((tab) => (
        <section
          key={tab.key}
          className={clsx(tab.key !== activeTabKey && "hidden")}
        >
          {tab.content}
        </section>
      ))}
    </div>
  );
}

function determineClassNames(className, tabs, headerBleed, type) {
  let tile = clsx(
    "styles.tile",
    "relative flex flex-col mt-8 bg-[#EEEEEE] px-8 py-[1.6rem] [&_>_*:first-child]:mt-0"
  );
  let header = clsx(
    "styles-header",
    "overflow-hidden [&_>_*:first-child]:mt-0 [&_>_*:first-child]:my-0"
  );
  let content = clsx("styles-content", "flex-grow");
  let action = clsx("styles-action", "flex justify-end mt-8");

  if (typeof className === "string") {
    tile += ` ${className}`;
  } else if (typeof className === "object") {
    if (className.tile) {
      tile += ` ${className.tile}`;
    }
    if (className.header) {
      header += ` ${className.header}`;
    }
    if (className.content) {
      content += ` ${className.content}`;
    }
    if (className.action) {
      action += ` ${className.action}`;
    }
  }

  if (tabs?.length > 0) {
    tile += clsx(" styles.hasTabs", "mt-[4rem]");
  }

  if (headerBleed) {
    tile += ` ${clsx(
      "styles-headerBleed",
      "pt-0 pl-0 pr-0 [&_.styles-header_h1]:pt-[1.6rem] [&_.styles-header_h1]:px-8 [&_.styles-header_h1]:pb-[0] [&_.styles-header_>_*:first-child]:mb-0 [&_.styles-content]:px-[1.6rem] [&_.styles-content]:py-[2rem] [&_.styles-content_>_*:first-child]:mt-[0] [&_.styles-action_>_*:first-child]:mt-[0] [&_.styles-action]:px-8 [&_.styles-action]:py-[1.6rem] [&_.styles-action]:pt-0 [&_.styles-action]:pb-0 [&_.styles-action]:px-[1.6rem] [&_.styles-action]:py-[2rem]"
    )}`;
  }

  if (type) {
    tile += clsx(" styles[type]");
  }

  return { tile, header, content, action };
}
