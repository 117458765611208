import React from "react";
import AnalysisTabs from "../AnalysisTabs/AnalysisTabs";
import clsx from "clsx";

function AnalysisHeader() {
  return (
    <header
      className={clsx(
        'styles["analysis-header"]',
        "flex flex-col sticky top-[0] left-[0] right-[0] bg-[linear-gradient(90deg,_#F87C26,_#FFBE01)] h-20 sm:h-[4.5rem] w-full"
      )}
    >
      <AnalysisTabs />
    </header>
  );
}

export default AnalysisHeader;
