import React, { useEffect } from "react";
import { ReactComponent as ProcessMapSvg } from "../../../assets/images/process-map.svg";
import "./ReportChart.css";

function ReportChart({ reports }) {
  useEffect(() => {
    for (const report of reports) {
      const tile = document.querySelector(`.pm-tiles__${report.category.slug}`);
      let tileClassName = "pm-tiles--color-";

      if (tile) {
        tile.classList.forEach((cn) => {
          if (cn.startsWith(tileClassName)) {
            tile.classList.remove(cn);
          }
        });

        setTimeout(() => {
          switch (report.evaluation.result) {
            case "MISSING_DATA":
              tileClassName += "missing-data";
              break;
            case "LOW":
              tileClassName += "low";
              break;
            case "MEDIUM":
              tileClassName += "medium";
              break;
            case "HIGH":
              tileClassName += "high";
              break;
            default:
              break;
          }

          tile.classList.add(tileClassName);
        }, 875);
      }
    }
  }, [reports]);

  return (
    <div className={""}>
      <ProcessMapSvg className="process-map" />
    </div>
  );
}

export default ReportChart;
