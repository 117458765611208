import React from "react";
import PageIntro from "../../Elements/Content/PageIntro";
import ImageTeaser from "../../Elements/Content/ImageTeaser";
import { useNavigate } from "react-router-dom";

import analysis from "../../../assets/images/Lib3.png";
import result from "../../../assets/images/Lib2.png";
import catalog from "../../../assets/images/Lib1.png";
import Button from "../../Elements/Content/Button";
import StepNumber from "../../Elements/Content/StepNumber";

// todo: responsiveness
function Introduction() {
  const navigate = useNavigate();
  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-[repeat(3,_1fr)] lg:grid-rows-[auto_1fr] lg:grid-flow-col gap-[2rem] [&_h2]:flex [&_h2]:items-center [&_h2]:leading-10">
      <PageIntro
        rootClassName="sm:col-span-2 lg:[grid-area:1_/_1_/_2_/_4]"
        title="Einführung"
        action={<Button onClick={() => navigate("/analyse")}>Weiter</Button>}
      >
        <p>
          In der heutigen digitalen Ära ist es wichtiger denn je, die richtige Digitalisierungssoftware für die spezifischen Anforderungen und Ziele Ihres Unternehmens zu finden. Angesichts der zahlreichen verfügbaren Lösungen kann diese Aufgabe jedoch überwältigend erscheinen.
        </p>
        <p>
          Unsere Digitale Produktberatung ist hier, um Licht ins Dunkel zu bringen. Durch eine gründliche Ist- und Soll-Analyse hilft das Tool Ihnen dabei, die optimale Software für Ihre Anforderungen zu identifizieren. Es berücksichtigt nicht nur den aktuellen Zustand Ihrer digitalen Landschaft, sondern auch Ihre zukünftigen Ziele und Bedürfnisse.
        </p>
      </PageIntro>

      <ImageTeaser
        className="!mt-0"
        image={analysis}
        alt=""
        action={<Button onClick={() => navigate("/analyse")}>Weiter</Button>}
      >
        <h2>
          <StepNumber number={1} isActive={true} /> Analyse
        </h2>
        <p>Ermitteln Sie einfach den Optimierungsbedarf Ihres Services.</p>
      </ImageTeaser>

      <ImageTeaser
        className="!mt-0"
        image={result}
        alt=""
        action={
          <Button className="hidden" onClick={() => navigate("/analyse")}>
            Weiter
          </Button>
        }
      >
        <h2>
          <StepNumber number={2} isActive={true} /> Report
        </h2>
        <p>Erhalten Sie einen umfassenden Einblick in Ihre Prozessschritte.</p>
      </ImageTeaser>

      <ImageTeaser
        className="!mt-0"
        image={catalog}
        alt=""
        action={
          <Button onClick={() => navigate("/katalog")}>
            Direkt zum Katalog
          </Button>
        }
      >
        <h2>
          <StepNumber number={3} isActive={true} /> Katalog
        </h2>
        <p>Entdecken Sie erprobte Optionen für Ihren Service.</p>
      </ImageTeaser>
    </div>
  );
}

export default Introduction;
