import AnalysisSubCategory from "./AnalysisSubCategory/AnalysisSubCategory";
import AnalysisStatement from "./AnalysisStatement/AnalysisStatement";

function AnalysisChildren({ questionSlug, categoryChildren }) {
  return (
    <>
      {categoryChildren.map((child) => {
        switch (child.type) {
          case "category":
            return (
              <AnalysisSubCategory
                key={child.id}
                questionSlug={questionSlug}
                category={child}
              />
            );
          case "statement":
            return (
              <AnalysisStatement
                key={child.id}
                questionSlug={questionSlug}
                statement={child}
              />
            );
          default:
            console.error("Unknown child type");
            return <div></div>;
        }
      })}
    </>
  );
}

export default AnalysisChildren;
