import React from "react";
import Button from "../../../Elements/Content/Button";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

function CatalogService({ service }) {
  const navigate = useNavigate();

  return (
    <li
      className={clsx(
        'styles["catalog-service"]',
        "grid grid-cols-[5fr_1fr] gap-[2rem] items-center px-4 py-3 odd:bg-[#e3edef]"
      )}
    >
      <div>
        <h4 className="m-0">{service.title}</h4>
        <p className="m-0 italic">
          {service.contacts
            .map((contact) => contact.company ?? contact.name ?? undefined)
            .filter((value, index, array) => array.indexOf(value) === index)
            .join(" / ")}
        </p>
      </div>
      <div>
        <Button onClick={() => navigate(`/katalog/${service.slug}`)}>
          Details
        </Button>
      </div>
    </li>
  );
}

export default CatalogService;
