import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-regular-svg-icons";
import Button from "../Button";
import Modal from "../Modal";
import Video from "../Video";
import youtubePreviewUrl from "../../../../utility/youtubePreviewUrl";
import clsx from "clsx";

function VideoTeaser({ embedId, hideButton, open, onClose }) {
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  const openVideo = () => setIsOpen(true);

  return (
    <div
      className={clsx(
        "styles-teaser",
        "text-center mb-8",
        hideButton && "styles-hideButton"
      )}
    >
      <button
        className={clsx(
          "styles-image",
          hideButton && "!mb-0",
          "relative flex overflow-hidden [aspect-ratio:1.7777777778] items-center justify-center w-full bg-cover mb-8 cursor-pointer",
          'before:bg-[rgba(255,_255,_255,_0.1)] before:rounded-[0.3rem] before:bottom-[0] before:content-[""] before:left-[0] before:absolute before:right-[0] before:top-[0] before:scale-[2] before:-translate-x-full before:skew-x-[30deg] before:[transition:all_325ms_ease-in-out]',
          "hover:before:scale-[2] hover:before:translate-x-[0] hover:before:skew-x-[30deg]"
        )}
        style={{ backgroundImage: `url(${youtubePreviewUrl(embedId)})` }}
        onClick={openVideo}
      >
        <FontAwesomeIcon
          className={clsx(
            "styles-icon",
            "text-[#e4953f]",
            "relative text-[4em] text-[#dd7a0f] [filter:drop-shadow(0rem_0.2rem_0.2rem_rgba(89,_106,_125,_0.9))] [transition:color_225ms_ease-in-out,_transform_225ms_ease-in-out]"
          )}
          icon={faCirclePlay}
        />
      </button>
      {!hideButton && (
        <Button onClick={openVideo}>Video zum KI-Einstieg anschauen</Button>
      )}
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          onClose && onClose();
        }}
        classNames={{
          content: clsx(
            "styles-modalContent",
            "!p-0 [aspect-ratio:16/9] max-h-[90vh]"
          ),
        }}
      >
        <Video embedId={embedId} />
      </Modal>
    </div>
  );
}

export default VideoTeaser;
