import React from "react";
import AppStep from "./AppStep/AppStep";

import clsx from "clsx";

function AppNavigation() {
  return (
    <nav
      className={clsx(
        "styles-navigation",
        "sticky top-[0] left-[0] right-[0] h-[4rem] sm:h-[4.5rem] bg-[linear-gradient(90deg,_#F87C26,_#FFBE01)] px-[.5rem] sm:px-4 py-[0] flex items-center justify-center"
      )}
    >
      <ol className="[list-style:none] m-0 p-0 flex">
        <li className='relative mr-[1.4rem] sm:mr-4 after:absolute after:w-[2px] after:h-8 after:top-[0] after:content-[""] sm:after:inline-block sm:after:relative sm:after:ml-4 sm:after:-top-[7px] sm:after:w-[10vw] sm:after:h-[2px] after:ml-[10px] after:opacity-10 sm:after:opacity-100 after:bg-[#fff] after:rounded-[5px] '>
          <AppStep number={1} label="Analyse" link="/analyse" />
        </li>
        <li className='mt-0 relative mr-[1.4rem] sm:mr-4 after:absolute after:w-[2px] after:h-8 after:top-[0] after:content-[""] sm:after:inline-block sm:after:relative sm:after:ml-4 sm:after:-top-[7px] sm:after:w-[10vw] sm:after:h-[2px] after:ml-[10px] after:opacity-10 sm:after:opacity-100 after:bg-[#fff] after:rounded-[5px] '>
          <AppStep number={2} label="Report" link="/report" />
        </li>
        <li className='mt-0 relative after:content-[""] after:inline-block'>
          <AppStep number={3} label="Katalog" link="/katalog" />
        </li>
      </ol>
    </nav>
  );
}

export default AppNavigation;
