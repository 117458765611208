import React, { useEffect } from "react";
import Button from "../../Content/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrosshairs, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

function RadioButton({ value, onChange }) {
  const [status, setStatus] = React.useState(value);

  useEffect(() => {
    setStatus(value);
  }, [value]);

  let className = clsx("styles-switch", "whitespace-nowrap");

  if (status === "ACTUAL") {
    className += clsx(
      " styles-actual",
      "[&_.button:first-child]:bg-[#0090aa] [&_.button:first-child]:text-[#fff]"
    );
  } else if (status === "TARGET")
    className += clsx(
      " ",
      "styles-target",
      "[&_.button:last-child]:bg-[#d007c8] [&_.button:last-child]:rounded-tl-[0] [&_.button:last-child]:rounded-br-[0.3rem] [&_.button:last-child]:rounded-tr-[0.3rem] [&_.button:last-child]:rounded-bl-[0] [&_.button:last-child]:text-[#fff]"
    );

  function change(value) {
    setStatus(value);
    onChange(value);
  }

  return (
    <div className={className}>
      <Button
        className={clsx(
          "styles-button",
          "!w-1/2  !pl-0 !pr-0 text-center",
          "before:bg-[rgba(0,_144,_170,_0.75)]",
          "rounded-tl-[0.3rem] !rounded-br-[0] !rounded-tr-[0] rounded-bl-[0.3rem] mr-[0.1rem]",
          status === "ACTUAL" ? "!bg-[#0090aa] text-[#fff]" : "!bg-[#8e9dae]"
        )}
        onClick={() => change("ACTUAL")}
      >
        <FontAwesomeIcon icon={faLocationDot} /> Ist
      </Button>
      <Button
        className={clsx(
          "styles-button",
          "before:bg-[rgba(208,_7,_200,_0.75)]",
          "!w-1/2  !pl-0 !pr-0 text-center",
          "!rounded-tl-[0] !rounded-br-[0.3rem] !rounded-tr-[0.3rem] !rounded-bl-[0]",
          status === "TARGET"
            ? "!bg-[#d007c8] !rounded-tl-[0] rounded-br-[0.3rem] rounded-tr-[0.3rem] !rounded-bl-[0] text-[#fff]"
            : "!bg-[#8e9dae]"
        )}
        onClick={() => change("TARGET")}
      >
        <FontAwesomeIcon icon={faCrosshairs} /> Soll
      </Button>
    </div>
  );
}

export default RadioButton;
