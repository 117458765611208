import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AnalysisChildren from "../../AnalysisChildren";
import { setCurrentCategory } from "../../../../../state/analysisSlice";
import AnalysisNavigation from "../AnalysisNavigation";
import Tile from "../../../../Elements/Content/Tile";
import AnalysisStatementHeader from "../../AnalysisStatementHeader/AnalysisStatementHeader";
import ReportChartDebugger from "../../../Report/ReportChartDebugger";
import clsx from "clsx";

function AnalysisMainCategory() {
  const survey = useSelector((state) => state.analysis.survey);
  const { slug } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mainCategory, setMainCategory] = useState();
  const [number, setNumber] = useState(0);

  useEffect(() => {
    const index = survey?.findIndex((category) => category.slug === slug);
    const mainCategory = survey?.[index ?? 0];

    if (mainCategory) {
      setNumber(index !== -1 ? index + 1 : 0);
      setMainCategory(mainCategory);
      dispatch(setCurrentCategory(mainCategory));
    } else {
      navigate("/analyse");
    }
    //eslint-disable-next-line
  }, [survey, slug]);

  return mainCategory ? (
    <>
      <Tile
        className={{
          tile: clsx(
            "styles-mainCategory [&_h1]:px-8 [&_h1]:!py-[1.6rem] [&_h1]:bg-[#B12A1C] [&_h1]:text-[#fff] [&_h1]:mb-0 [&_h1]:lg:block [&_h1]:hidden"
          ),
          content: clsx("styles-content !p-0"),
        }}
        action={<AnalysisNavigation />}
        headerBleed={true}
        header={
          <h1>
            {number}. {mainCategory.title}
          </h1>
        }
      >
        {mainCategory.children[0].type === "statement" ? (
          <AnalysisStatementHeader />
        ) : null}
        <AnalysisChildren
          questionSlug={mainCategory.slug}
          categoryChildren={mainCategory.children}
        />
      </Tile>
      <ReportChartDebugger
        questionSlug={mainCategory.slug}
        category={mainCategory}
      />
    </>
  ) : (
    <div>??</div>
  );
}

export default AnalysisMainCategory;
