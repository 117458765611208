import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  youtubeVideo: false,
};

export const consentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
    allowYouTubeVideo: (state) => ({
      ...state,
      youtubeVideo: true,
    }),
  },
});

export const { allowYouTubeVideo } = consentSlice.actions;
export default consentSlice.reducer;
