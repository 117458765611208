import React from "react";
import clsx from "clsx";

function StepNumber({ number, isActive }) {
  return (
    <span
      className={clsx(
        "content-[attr(title)] font-semibold overflow-hidden pointer-events-none select-none inline-block w-8 sm:w-10 h-8 sm:h-10 leading-[2rem] sm:leading-10 text-center rounded-[50%] text-[#596a7d] bg-[#FEFAB3] text-base sm:text-[1.4rem] mr-2",
        isActive && "!text-white !bg-[#dd7a0f]"
      )}
      title={"" + number}
    >
      {number}
    </span>
  );
}

export default StepNumber;
