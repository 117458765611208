import React from "react";
import clsx from "clsx";
import { useMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { createProgressSelector } from "../../../../state/selectors";
import conditionalClassName from "../../../../utility/conditionalClassName";

function AnalysisProgressBar({ category, className }) {
  const percent = useSelector(createProgressSelector(category?.id));
  const match = useMatch("/analyse/" + category?.slug);
  className = clsx(
    "styles-progress",
    "relative h-8 leading-8 bg-[#fff] opacity-75 [transition:opacity_250ms_ease-in-out]",
    conditionalClassName(className)
  );

  if (percent > 60) {
    className +=
      " " +
      clsx(
        'styles["text-left"]',
        "[&_.styles-progressBar]:after:text-[#fff] [&_.styles-progressBar]:after:-translate-x-full"
      );
  }

  if (percent === 100) {
    className +=
      " " +
      clsx(
        "styles-done",
        "[&_.styles-progressBar]:after:[transition:left_250ms_ease-in-out,_opacity_250ms_ease-in-out_500ms] [&_.styles-progressBar]:after:!opacity-0",
        "[&_.styles-icon]:!opacity-100"
      );
  }

  if (category && match) {
    className +=
      " " +
      clsx(
        "styles-active",
        "opacity-100 [&_.styles-progressBar]:after:opacity-100"
      );
  }

  if (!category) {
    className +=
      " " +
      clsx(
        "styles-totalProgress",
        "opacity-100 [&_.styles-progressBar]:after:!opacity-100 [&_styles-icon]:hidden"
      );
  }

  const style = {
    "--progress": `${percent}%`,
    "--progress-scale": `${percent / 100}`,
  };

  return (
    <div className={className} style={style}>
      <div
        className={clsx(
          "styles-progressBar",
          "relative h-full",
          'before:block before:content-[""] before:absolute before:top-[0] before:left-[0] before:bottom-[0] before:bg-[#dd7a0f] before:w-full before:scale-x-[var(--progress-scale)] before:origin-[left_top] before:[transition:transform_250ms_ease-in-out]',
          "after:block after:content-[attr(data-progress)] after:left-[var(--progress)] after:[transition:left_250ms_ease-in-out,_opacity_250ms_ease-in-out] after:absolute after:text-[0.85rem] after:px-2 after:py-[0] after:opacity-0"
        )}
        data-progress={`${percent}%`}
      />
      <FontAwesomeIcon
        icon={faCheck}
        className={clsx(
          "styles-icon",
          "absolute opacity-0 top-[0.4rem] right-2 text-[#fff] text-[1.2rem] [transition:opacity_250ms_ease-in-out_750ms]"
        )}
      />
    </div>
  );
}

export default AnalysisProgressBar;
