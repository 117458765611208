import {
  faQuestion,
  faRocket,
  faScrewdriverWrench,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";

const ReportResult = {
  MISSING_DATA: "MISSING_DATA",
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
};

export const ReportResultOptions = [
  ReportResult.MISSING_DATA,
  ReportResult.LOW,
  ReportResult.MEDIUM,
  ReportResult.HIGH,
];

export const ReportResultLabels = {
  [ReportResult.MISSING_DATA]: "Es fehlen Daten",
  [ReportResult.LOW]: "Ungenutztes Potenzial",
  [ReportResult.MEDIUM]: "Potenzial weiter ausschöpfen",
  [ReportResult.HIGH]: "Bereits gut aufgestellt",
};

export const ReportResultIcons = {
  [ReportResult.MISSING_DATA]: faQuestion,
  [ReportResult.LOW]: faScrewdriverWrench,
  [ReportResult.MEDIUM]: faRocket,
  [ReportResult.HIGH]: faTrophy,
};
