export default class AnalyzerService {
  static thresholdDistribution = new Map([
    ["LOW", 0],
    ["MEDIUM", 0.51],
    ["HIGH", 0.91],
  ]);

  static findAllStatements(child) {
    if (child.type === "statement") {
      return [child];
    } else {
      return child.children.flatMap(this.findAllStatements.bind(this));
    }
  }

  static calculateProgress(category, answers) {
    const allStatements = this.findAllStatements(category);
    const allAnswers = this.findAllAnswers(allStatements, answers);
    return Math.ceil((allAnswers.length / allStatements.length) * 100);
  }

  static findAllAnswers(statements, answers) {
    return statements
      .map((statement) =>
        answers.find((answer) => answer.statement.id === statement.id)
      )
      .filter((answer) => answer !== undefined && answer.state !== "UNKNOWN");
  }

  static createReport(category, answers) {
    const allStatements = this.findAllStatements(category);
    const allAnswers = this.findAllAnswers(allStatements, answers);
    const actual = this.calculateScore(allAnswers, "ACTUAL");
    const target = this.calculateScore(allAnswers, "TARGET");
    // const maxScore = this.calculateScoreCeiling(allStatements);
    const maxScore = this.calculateWeightedMaxScore(allAnswers);
    const score = actual !== null && target !== null ? actual - target : null;

    return {
      category,
      total: {
        actual,
        target,
      },
      score,
      maxScore,
      evaluation: this.evaluateReport(score, maxScore),
    };
  }

  static calculateScore(answers, state) {
    if (answers.length === 0) {
      return null;
    }

    const score = answers
      .filter((answer) => answer.state === state)
      .map((answer) => answer.priority * answer.statement.weight)
      .reduce((a, b) => a + b, 0);

    return Math.ceil(score / answers.length);
  }

  // todo: remove when decision process about report calculation is finished
  static calculateTotalMaxScore(statements) {
    return (
      statements
        .map((statement) => statement.weight * 3)
        .reduce((a, b) => a + b, 0) / statements.length
    );
  }

  static calculateWeightedMaxScore(answers) {
    if (answers.length === 0) {
      return null;
    }

    const maxScore =
      answers
        .map((answer) => answer.statement.weight * answer.priority)
        .reduce((a, b) => a + b, 0) / answers.length;

    return Math.ceil(maxScore);
  }

  static evaluateReport(score, maxScore) {
    if (score === null || maxScore === null) {
      return { steps: [], result: "MISSING_DATA" };
    }

    const absMaxScore = maxScore * 2;
    const steps = Array.from(this.thresholdDistribution.values()).map(
      (threshold) => Math.ceil(absMaxScore * threshold)
    );

    // we push the score into the same positive range as the ceiling
    const absScore = score + maxScore;

    let result = "MISSING_DATA";

    if (absScore >= steps[2]) {
      result = "HIGH";
    } else if (absScore >= steps[1]) {
      result = "MEDIUM";
    } else if (absScore >= steps[0]) {
      result = "LOW";
    }

    return { steps, result };
  }
}
