import React from "react";
import { useNavigate } from "react-router-dom";
import ProcessNavigation from "../../../Elements/Navigation/ProcessNavigation";
import PageIntro from "../../../Elements/Content/PageIntro";
import Tile from "../../../Elements/Content/Tile";
import { useSelector, useDispatch } from "react-redux";
import { answersSelector, surveySelector } from "../../../../state/selectors";
import CatalogCategory from "../CatalogCategory";
import { setCurrentService } from "../../../../state/catalogSlice";
import AnalyzerService from "../../../../services/AnalyzerService";
import clsx from "clsx";

const recommend = ["LOW", "MEDIUM"];

function Catalog() {
  const survey = useSelector(surveySelector);
  const answers = useSelector(answersSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigation = (
    <ProcessNavigation onPrevious={() => navigate("/report")} />
  );

  dispatch(setCurrentService(null));

  const recommendations = survey
    .map((c) => AnalyzerService.createReport(c, answers))
    .filter((r) => recommend.includes(r.evaluation.result))
    .map((r) => r.category.module);

  const tabs = [
    {
      label: "Empfohlene Module",
      key: "recommended",
      content: (
        <>
          {recommendations.length ? (
            createCategoryList(survey, recommendations)
          ) : (
            <p className={clsx("style.notice", "font-bold text-center")}>
              Damit wir Ihnen Module empfehlen können, müssen Sie die Analyse
              durchgeführt haben.
            </p>
          )}
        </>
      ),
    },
    {
      label: "Liste aller Module",
      key: "all",
      content: createCategoryList(survey),
    },
  ];

  return (
    <div className="overflow-hidden">
      <PageIntro title="Katalog" action={navigation}>
        <p>
          Entdecken Sie im Servicekatalog erprobte Optionen für Ihren Service.
          Mit Ihrer Selbsteinschätzung erhalten Sie in der Rubrik „empfohlene
          Module“ eine Vorauswahl der jeweiligen Prozessschritte mit
          entsprechenden Anwendungsbeispielen zur KI-Anwendung im Technischen
          Service aus Service-Meister. Unter „Liste aller Module“ finden Sie
          alle Use Cases des Projekts.
        </p>
      </PageIntro>
      <Tile
        className="!px-4 sm:!px-8"
        tabs={tabs}
        activeTab={recommendations.length === 0 ? tabs[1].key : tabs[0].key}
      ></Tile>
    </div>
  );
}

function createCategoryList(survey, filter = [1, 2, 3, 4, 5, 6]) {
  return survey
    .filter((c) => filter.includes(c.module))
    .map((category) => (
      <CatalogCategory key={category.id} category={category} />
    ));
}

export default Catalog;
