import React from "react";
import Tile from "../Tile";

function ImageTeaser({ image, alt, children, action, className }) {
  if (typeof className === "string") {
    className += ` ${"bg-[#EEEEEE] [&_img]:w-full"}`;
  } else if (typeof className === "object") {
    className.tile += ` ${"bg-[#EEEEEE] [&_img]:w-full"}`;
  } else {
    className = "bg-[#EEEEEE] [&_img]:w-full";
  }

  return (
    <Tile
      className={className}
      header={<img src={image} alt={alt} />}
      headerBleed={true}
      action={action}
    >
      {children}
    </Tile>
  );
}

export default ImageTeaser;
