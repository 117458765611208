import React from "react";
import PageIntro from "../../../Elements/Content/PageIntro";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../../Elements/Content/Button.jsx";
import clsx from "clsx";

function AnalysisStart() {
  const navigate = useNavigate();
  const survey = useSelector((state) => state.analysis.survey);
  const startUrl = `/analyse/${survey[0]?.slug ?? ""}`;

  return (
    <div className={clsx("flex flex-col justify-center", "styles-start")}>
      <PageIntro title="Analyse">
        <p>
          Bevor wir den idealen Weg in Ihre digitale Zukunft ebnen können, müssen wir verstehen, wo Sie aktuell stehen und wohin Sie möchten. Die Analyse-Phase ist das Herzstück unserer digitalen Produktberatung und der entscheidende erste Schritt auf dieser Reise.
        </p>
        <p>In dieser Phase gehen wir folgendermaßen vor:</p>
        <p>
          <b>Technologische Infrastruktur:</b><br />

          In dieser Phase beschäftigen wir uns mit der gesamten technologischen Landschaft des Unternehmens. Es geht darum, ein klares Bild von vorhandenen Hard- und Softwarelösungen, genutzten Plattformen und Systemen sowie deren Integration und Vernetzung zu erhalten.
        </p>
        <p>
          <b>Prozesse und Workflows:</b><br />

          Hier konzentrieren wir uns auf die Abläufe und Verfahren im Unternehmen. Welche Hauptprozesse definieren den Betriebsablauf? Wie sind diese organisiert und welche Schnittstellen gibt es zwischen den Abteilungen?
        </p>
        <p>
          <b>Mitarbeiter und Kultur:</b><br />

          Die Menschen sind das Herzstück jedes Unternehmens. In dieser Phase erfassen wir die Kompetenzen, das Know-how und die Einstellung der Mitarbeiter hinsichtlich Digitalisierung. Gleichzeitig beleuchten wir die Unternehmenskultur und wie diese die Einführung neuer Technologien beeinflusst.
        </p>
        <p>
          <b>Kunden und Service:</b><br />

          Hier dreht sich alles um die Interaktion mit Kunden. Wie wird mit Kunden kommuniziert? Über welche Kanäle erreicht man sie und welche digitalen Dienstleistungen oder Produkte werden angeboten?
        </p>
        <p>
          <b>Daten und Analytik:</b><br />

          Daten sind das Gold des 21. Jahrhunderts. In dieser Phase geht es darum, wie das Unternehmen Daten sammelt, verarbeitet und verwendet. Es wird auch betrachtet, welche Tools für die Datenanalyse genutzt werden und wie diese Erkenntnisse in geschäftlichen Mehrwert umgewandelt werden können.
        </p>
        <p>
          <b>Sicherheit und Compliance:</b><br />

          Sicherheit ist in der digitalen Welt von entscheidender Bedeutung. Hier schauen wir uns an, wie das Unternehmen seine digitalen Assets schützt, welche Sicherheitsprotokolle vorhanden sind und wie es sicherstellt, dass alle gesetzlichen und regulatorischen Anforderungen erfüllt werden.
        </p>
      </PageIntro>
      <Button
        className={clsx("!my-[2rem] !mx-auto", "styles-button")}
        onClick={() => navigate(startUrl)}
      >
        Analyse Beginnen
      </Button>
    </div >
  );
}

export default AnalysisStart;
