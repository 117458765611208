import { useDispatch } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes";
import { initSurvey } from "./state/analysisSlice";
import analysisJson from "./assets/analysis.json";
import catalogJson from "./assets/catalog.json";
import { initCatalog } from "./state/catalogSlice";
import "./assets/font-awesome.css";

function App() {
  const dispatch = useDispatch();
  dispatch(initSurvey(analysisJson));
  dispatch(initCatalog(catalogJson));

  return <RouterProvider router={routes} />;
}

export default App;
