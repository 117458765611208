import React from "react";
import logo from "../../../../assets/images/logo_small.png";
import { Link, useLocation } from "react-router-dom";

import AppMobileMenu from "../AppMobileMenu";
import clsx from "clsx";

function AppHeader() {
  const { pathname } = useLocation();

  return (
    <header
      className={clsx(
        "styles-header",
        "relative flex items-center sm:h-[4.5rem] sm:px-4 sm:py-[0] bg-[#000000]",
        "h-[4rem] p-[0_0.5rem]"
      )}
    >
      <img
        title="LadewigGmbH"
        alt="Ladewig-GmbH"

        src={logo}
        width="50"
        height="65"
      />
      {!(pathname === "/") ? (
        <Link
          className={`btn btn--ghost ${clsx(
            "styles-home",
            "ml-auto hidden sm:block"
          )}`}
          to="/"
        >
          Zur Einführung
        </Link>
      ) : null}

      <AppMobileMenu className={clsx("styles-mobileMenu", "ml-auto")} />
    </header>
  );
}

export default AppHeader;
