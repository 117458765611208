import React from "react";
import Tile from "../../Elements/Content/Tile";
import { useDispatch, useSelector } from "react-redux";
import { answersSelector, surveySelector } from "../../../state/selectors";
import AnalyzerService from "../../../services/AnalyzerService";
import {
  ReportResultIcons,
  ReportResultLabels,
} from "../../../interfaces/ISurveyReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../Elements/Content/Button";
import { updateAnswer, resetAnswers } from "../../../state/analysisSlice";
import clsx from "clsx";

function ReportChartDebugger({ category }) {
  const dispatch = useDispatch();
  const survey = useSelector(surveySelector);
  const answers = useSelector(answersSelector);
  const categories = category ? [category] : survey;

  function createRandomAnswers() {
    if (!category) dispatch(resetAnswers());
    survey
      .flatMap((category) => AnalyzerService.findAllStatements(category))
      .map((statement) => ({
        statement: statement,
        priority: Math.floor(Math.random() * 4),
        state: Math.random() > 0.5 ? "ACTUAL" : "TARGET",
      }))
      .every((answer) => {
        if (category) {
          let items = category.children.flatMap(
            (category) => category.children
          );
          if (category.children[0].type === "statement")
            items = category.children;

          const idx = items.findIndex((el) => el?.id === answer?.statement?.id);
          if (idx > -1) {
            dispatch(updateAnswer(answer));
          }
        } else {
          dispatch(updateAnswer(answer));
        }
        return true;
      });
  }

  return (
    <Tile
      className={clsx(
        "styles-reportDebugger hidden lg:block",
        "[&_.btn]:mt-4 [&_.btn]:mx-2 [&_.btn]:mb-[0]"
      )}
      header={
        <h3>
          Debugging Sicherheit und Compliance
          {category ? ` für ${category.navigationTitle ?? category.title}` : ""}
        </h3>
      }
    >
      <table className="w-full">
        <thead>
          <tr>
            <th className="px-[0.8rem] py-2">Kategorie</th>
            <th className="px-[0.8rem] py-2">Basis</th>
            <th className="px-[0.8rem] py-2">Ist</th>
            <th className="px-[0.8rem] py-2">Soll</th>
            <th className="px-[0.8rem] py-2">Punkte</th>
            <th className="px-[0.8rem] py-2"></th>
            <th className="px-[0.8rem] py-2">Sicherheit und Compliance</th>
            <th className="px-[0.8rem] py-2">Schwellenwerte</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => {
            const report = AnalyzerService.createReport(category, answers);
            return (
              <tr className="[&:nth-child(odd)_td]:bg-[#dce9ec]" key={index}>
                <td className="px-[0.8rem] py-2">
                  {category.navigationTitle ?? category.title}
                </td>
                <td className="px-[0.8rem] py-2">
                  {report.maxScore ? report.maxScore : "k.A."}
                </td>
                <td className="px-[0.8rem] py-2">
                  {report.total.actual ?? "k.A."}
                </td>
                <td className="px-[0.8rem] py-2">
                  {report.total.target !== null && report.total.target >= 0
                    ? report.total.target * -1
                    : "k.A."}
                </td>
                <td className="px-[0.8rem] py-2">
                  <strong>
                    {" "}
                    {report.score !== null && report.maxScore !== null
                      ? report.maxScore + report.score
                      : "k.A."}{" "}
                  </strong>
                  / {report.maxScore ? report.maxScore * 2 : "k.A."}
                </td>
                <td
                  className={clsx(
                    "px-[0.8rem] py-2",
                    "styles-reportColor",
                    "text-white",
                    report.evaluation.result === "MISSING_DATA" &&
                      "!bg-[#8e9dae]",
                    report.evaluation.result === "HIGH" && "!bg-[#045a71]",
                    report.evaluation.result === "MEDIUM" && "!bg-[#0ca1e6]",
                    report.evaluation.result === "LOW" && "!bg-[#d007c8]",
                    "styles[report.evaluation.result]",
                    report.evaluation.result
                  )}
                >
                  <FontAwesomeIcon
                    icon={ReportResultIcons[report.evaluation.result]}
                  />
                </td>
                <td
                  className={clsx(
                    "px-[0.8rem] py-2",
                    "styles-reportColor",
                    "text-white",
                    report.evaluation.result,
                    report.evaluation.result === "MISSING_DATA" &&
                      "!bg-[#8e9dae]",
                    report.evaluation.result === "HIGH" && "!bg-[#045a71]",
                    report.evaluation.result === "MEDIUM" && "!bg-[#0ca1e6]",
                    report.evaluation.result === "LOW" && "!bg-[#d007c8]",
                    "styles[report.evaluation.result]",
                    report.evaluation.result
                  )}
                >
                  {ReportResultLabels[report.evaluation.result]}
                </td>
                <td className="px-[0.8rem] py-2" style={{ fontSize: "0.8rem" }}>
                  {Array.from(AnalyzerService.thresholdDistribution.keys()).map(
                    (result, index) => {
                      const step = report.evaluation.steps[index];
                      const next =
                        report.evaluation.steps[index + 1] ??
                        (report.maxScore ?? 0) * 2;
                      return (
                        <div
                          key={index}
                          className={clsx(
                            "styles-reportColor",
                            result,
                            "styles[result]",
                            result === "LOW" && "text-[#d007c8]",
                            result === "MEDIUM" && "text-[#0ca1e6]",
                            result === "HIGH" && "text-[#045a71]"
                          )}
                        >
                          <FontAwesomeIcon icon={ReportResultIcons[result]} />
                          <span>
                            {" "}
                            {step} bis {next}{" "}
                          </span>
                          <span>
                            (&gt;=
                            {(AnalyzerService.thresholdDistribution.get(
                              result
                            ) ?? 0) * 100}
                            %)
                          </span>
                        </div>
                      );
                    }
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Button onClick={() => createRandomAnswers()}>
        Zufällige Antworten generieren
      </Button>
      <Button
        style={{ backgroundColor: "red" }}
        onClick={() => {
          if (category) {
            let items = category.children.flatMap(
              (category) => category.children
            );
            if (category.children[0].type === "statement")
              items = category.children;

            dispatch(resetAnswers({ current: true, items }));
          } else dispatch(resetAnswers());
        }}
      >
        Alle Antworten zurücksetzen
      </Button>
      <dl className="hidden lg:flex [flex-flow:row_wrap] border-[1px_1px_0_0] my-4">
        <dt className="[flex-basis:20%] px-[4px] py-[2px] text-right font-bold">
          Basis
        </dt>
        <dd className="[flex-basis:70%] flex-grow m-0 px-[4px] py-[2px]">
          Summe Wichtigkeit * Gewicht aller Antworten deren Wichtigkeit &gt; 0
          ist
        </dd>
        <dt className="[flex-basis:20%] px-[4px] py-[2px] text-right font-bold">
          Punkte
        </dt>
        <dd className="[flex-basis:70%] flex-grow m-0 px-[4px] py-[2px]">
          Basis + Ist + Soll
        </dd>
        <dt className="[flex-basis:20%] px-[4px] py-[2px] text-right font-bold">
          Maximale Punktzahl
        </dt>
        <dd className="[flex-basis:70%] flex-grow m-0 px-[4px] py-[2px]">
          Basis * 2
        </dd>
      </dl>
    </Tile>
  );
}

export default ReportChartDebugger;
