import React, { useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";

/*
 * TODO: Big start button like in click dummy
 */
function Analysis() {
  const [answers, setAnswers] = useState([]);

  return (
    <>
      <Outlet context={{ answers, setAnswers }} />
    </>
  );
}

export default Analysis;

export function useAnswers() {
  return useOutletContext();
}
