import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tile from "../Tile";
import VideoTeaser from "../VideoTeaser";
import Button from "../Button";
import ReportFlower from "../ReportFlower";
import AnalyzerService from "../../../../services/AnalyzerService";
import { answersSelector, surveySelector } from "../../../../state/selectors";
import clsx from "clsx";

function ModuleTeaser({ category }) {
  const survey = useSelector(surveySelector);
  const answers = useSelector(answersSelector);
  const [isOpen, setIsOpen] = React.useState(false);
  const [report, setReport] = useState(null);

  useEffect(() => {
    setReport(AnalyzerService.createReport(category, answers));
  }, [category, survey, answers]);

  const header = (
    <>
      <VideoTeaser
        embedId={category.youtubeId}
        hideButton={true}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
  const action = (
    <Button onClick={() => setIsOpen(true)}>Video zu Modul anschauen</Button>
  );

  return (
    <Tile
      className={{
        tile: clsx(
          "styles-moduleTeaser",
          "!mt-0 !bg-transparent [&_img]:w-full"
        ),
        header: clsx("styles-header", "relative mb-0"),
        content: clsx("styles-content", "px-4 py-[0]"),
        action: clsx("styles-action", "px-4 py-[0]"),
      }}
      header={header}
      headerBleed={true}
      action={action}
    >
      <header
        className={clsx(
          "styles-title",
          "flex items-center justify-items-center leading-6"
        )}
      >
        <div className={clsx("styles-flower", "block mr-4")}>
          <ReportFlower
            module={category.module}
            result={report?.evaluation?.result ?? "missing-data"}
          />
        </div>
        <div>
          <h4 className="ml-[0] mr-[0] my-[0] font-normal">
            Modul {category.module}
          </h4>
          <h3 className="m-0">{category.title}</h3>
        </div>
      </header>
      <p>{category.description}</p>
    </Tile>
  );
}

export default ModuleTeaser;
