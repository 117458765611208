import { combineReducers } from "@reduxjs/toolkit";
import analysis from "./analysisSlice";
import catalog from "./catalogSlice";
import consent from "./consentSlice";

export const rootReducer = combineReducers({
  analysis,
  catalog,
  consent,
});
