import React from "react";

import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import clsx from "clsx";

function Modal({ open, onClose, children, classNames }) {
  return (
    <ReactModal
      isOpen={open}
      onRequestClose={onClose}
      className={clsx(
        "styles-content",
        "relative min-w-[90vw] border-[1px] border-[solid] border-[#ccc] bg-[#fff] rounded-[4px] outline-[none] p-[20px] overflow-visible [box-shadow:0_0_1rem_rgba(34,_34,_34,_0.4)]",
        classNames?.content
      )}
      overlayClassName={clsx(
        "styles-modal",
        "flex fixed bg-[rgba(0,_0,_0,_0.3)] justify-center items-center inset-0 z-[1000]",
        classNames?.overlay
      )}
    >
      <Button
        className={clsx(
          "styles-close",
          "!absolute right-[calc(50%-1.5rem)] bottom-[-4rem] sm:-top-8 sm:-right-8 !text-[2rem] !w-12 !h-12 !p-0 !text-center !rounded-[50%] [box-shadow:0_0_1rem_rgba(34,_34,_34,_0.4)]"
        )}
        onClick={onClose}
      >
        <FontAwesomeIcon className="leading-[3rem]" icon={faXmark} />
      </Button>
      {children}
    </ReactModal>
  );
}

export default Modal;
