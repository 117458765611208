import React from "react";

import { updateAnswer } from "../../../../state/analysisSlice";
import { useDispatch, useSelector } from "react-redux";
import RangeSlider from "../../../Elements/Forms/RangeSlider";
import RadioButton from "../../../Elements/Forms/RadioButton";
import { answersSelector } from "../../../../state/selectors";
import clsx from "clsx";

function AnalysisStatement({ questionSlug, statement }) {
  const answers = useSelector(answersSelector);
  const dispatch = useDispatch();
  const currentAnswer = answers.find((a) => a.statement.id === statement.id);

  function giveAnswer({ priority, state }) {
    dispatch(
      updateAnswer({
        statement,
        ...(priority !== undefined ? { priority } : {}),
        ...(state !== undefined ? { state } : {}),
      })
    );
  }

  return (
    <div
      className={clsx(
        "styles-analysisStatement overflow-hidden",
        "gap-[0_3rem] items-center bg-[rgba(54,_123,_141,_0.2)] grid sm:grid-flow-col sm:grid-cols-[1fr_10rem_10rem] sm:grid-rows-[1fr] pl-8 pr-8 bg-[#EEEEEE] pt-4 pb-4 [&:nth-child(even)]:bg-[#FEE6CC] [&:nth-child(odd)]:bg-[#FFF8EF]",
        "grid-cols-[1fr] grid-rows-[1fr] grid-flow-row"
      )}
      data-questionSlug={questionSlug}
    >
      <span
        className={clsx(
          "styles-label block w-full font-bold sm:font-normal mb-4 sm:mb-0"
        )}
      >
        {statement.title}
      </span>
      <RadioButton
        value={currentAnswer?.state ?? "UNKNOWN"}
        onChange={(state) => giveAnswer({ state })}
      />
      <span className={clsx("styles-sliderLabel", "block my-4 sm:hidden")}>
        Wichtigkeit
      </span>
      <RangeSlider
        min={0}
        max={3}
        step={1}
        defaultValue={currentAnswer?.priority ?? 0}
        className={clsx(
          "styles-slider",
          "mt-[0.7rem] pt-[0] px-4 sm:px-0 pb-12 sm:pb-0"
        )}
        onChange={(event) => giveAnswer({ priority: event[0] })}
      />
    </div>
  );
}

export default AnalysisStatement;
