import React from "react";
import { Link, useMatch } from "react-router-dom";

import StepNumber from "../../../Content/StepNumber";
import clsx from "clsx";

function AppStep({ number, label, link }) {
  const match = useMatch(link + "/*");

  // const className = match ? styles.step + " " + styles.active : styles.step;

  return (
    <Link
      className={clsx(
        match && "styles.active",
        "styles.step inline-flex items-center text-[#fff] no-underline"
      )}
      to={link}
    >
      <StepNumber number={number} isActive={Boolean(match)} />
      <span
        className={clsx(
          match && "font-semibold block sm:inline",
          "styles-text content-[attr(title)] block pointer-events-none select-none text-base sm:text-[1.1rem] after:content-[attr(title)] after:block after:font-semibold after:h-[0] after:overflow-hidden after:pointer-events-none after:select-none after:invisible"
        )}
        title={label}
      >
        {label}
      </span>
    </Link>
  );
}

export default AppStep;
