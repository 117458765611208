import React from "react";
import {
  ReportResultIcons,
  ReportResultLabels,
  ReportResultOptions,
} from "../../../interfaces/ISurveyReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

function ReportChartLegend() {
  return (
    <ul
      className={clsx(
        'styles["report-chart-legend"]',
        "[list-style:none] m-0 p-0 grid grid-cols-[repeat(auto-fit,_270px)] grid-rows-[repeat(2,_min-content)] gap-[1rem] sm:gap-[1.4rem] justify-center"
      )}
    >
      {ReportResultOptions.map((reportResult, index) => (
        <li
          key={`rcl-${index}`}
          className={clsx(
            "styles-item",
            "flex items-center whitespace-nowrap",
            "styles[`result-${reportResult}`]"
          )}
        >
          <span
            className={clsx(
              "styles-icon",
              "inline-block w-12 h-12 text-[1.8rem] leading-[3rem] text-[#fff] rounded-[50%] text-center mr-2 flex-shrink-0",
              reportResult === "MISSING_DATA" && "bg-[#8e9dae]",
              reportResult === "LOW" && "bg-[#d007c8]",
              reportResult === "MEDIUM" && "bg-[#0ca1e6]",
              reportResult === "HIGH" && "bg-[#045a71]"
            )}
          >
            <FontAwesomeIcon icon={ReportResultIcons[reportResult]} />
          </span>
          <span
            className={clsx("styles-label", "whitespace-nowrap text-[1rem]")}
          >
            {ReportResultLabels[reportResult]}
          </span>
        </li>
      ))}
    </ul>
  );
}

export default ReportChartLegend;
