import { createSlice } from "@reduxjs/toolkit";
import uniqueId from "lodash/uniqueId";
import AnalyzerService from "../services/AnalyzerService";
import { slugify } from "../components/Pages/Analysis/slugify";

const initialState = {
  currentCategory: null,
  answers: [],
  survey: [],
  progress: {
    total: 0,
    byCategory: {},
  },
};

export const analysisSlice = createSlice({
  name: "analysis",
  initialState,
  reducers: {
    initSurvey: (state, action) => {
      return {
        ...state,
        survey: convert(action.payload),
      };
    },
    setCurrentCategory: (state, action) => {
      return {
        ...state,
        currentCategory: action.payload,
      };
    },
    updateAnswer: (state, action) => {
      const answers = [...state.answers];

      const index = answers.findIndex(
        (answer) => answer.statement.id === action.payload.statement.id
      );

      // set answer
      if (index !== -1) {
        answers[index] = {
          ...answers[index],
          ...action.payload,
        };
      } else {
        answers.push({
          ...action.payload,
          state: action.payload.state ?? "UNKNOWN",
          priority: action.payload.priority ?? 0,
        });
      }

      // update progress
      const byCategory = {};

      for (const category of state.survey) {
        byCategory[category.id] = AnalyzerService.calculateProgress(
          category,
          answers
        );
      }

      const total =
        Object.values(byCategory).reduce((acc, curr) => acc + curr, 0) /
        Object.values(byCategory).length;

      state.answers = answers;
      state.progress = {
        total: Math.ceil(total),
        byCategory,
      };
    },
    resetAnswers: (state, action) => {
      let answers = [...state.answers];

      if (action?.payload?.current) {
        const filteredAnswers = answers.filter(
          (answer) =>
            !action.payload.items.find((el) => el.id === answer.statement.id)
        );

        answers = filteredAnswers;

        // update progress
        const byCategory = {};

        for (const category of state.survey) {
          byCategory[category.id] = AnalyzerService.calculateProgress(
            category,
            answers
          );
        }

        const total =
          Object.values(byCategory).reduce((acc, curr) => acc + curr, 0) /
          Object.values(byCategory).length;

        return {
          ...state,
          answers,
          progress: {
            total: Math.ceil(total),
            byCategory,
          },
        };
      }

      return {
        ...state,
        answers: [],
        progress: {
          total: 0,
          byCategory: {},
        },
      };
    },
    updateProgress: (state) => {
      const byCategory = {};

      for (const category of state.survey) {
        byCategory[category.id] = AnalyzerService.calculateProgress(
          category,
          state.answers
        );
      }

      return {
        ...state,
        progress: {
          total: 0,
          byCategory,
        },
      };
    },
  },
});

export const { initSurvey, setCurrentCategory, updateAnswer, resetAnswers } =
  analysisSlice.actions;
export default analysisSlice.reducer;

function convert(json) {
  // create slugs for main categories
  return json.survey.map((category) => ({
    ...category,
    id: category.id ?? createId(),
    slug: slugify(category.navigationTitle ?? category.title),
    children: category.children.map(decorate),
  }));
}

function decorate(child) {
  return {
    ...child,
    id: child.id ?? createId(),
    ...(child.type === "category" && {
      children: child.children.map(decorate),
    }),
  };
}

function createId() {
  return uniqueId("analysis-child-");
}
