import React from "react";
import clsx from "clsx";
import Button from "../../Content/Button";

function ProcessNavigation({ onNext, onPrevious }) {
  return (
    <nav className={clsx("styles-navigation", "w-full flex")}>
      {onPrevious ? (
        <Button className={clsx("styles-prev")} onClick={onPrevious}>
          Zurück
        </Button>
      ) : null}
      {onNext ? (
        <Button className={clsx("styles-next", "ml-auto")} onClick={onNext}>
          Weiter
        </Button>
      ) : null}
    </nav>
  );
}

export default ProcessNavigation;
