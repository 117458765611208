import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProcessNavigation from "../../../Elements/Navigation/ProcessNavigation";

function AnalysisNavigation() {
  const currentCategory = useSelector(
    (state) => state.analysis.currentCategory
  );
  const survey = useSelector((state) => state.analysis.survey);
  const navigate = useNavigate();

  function goBack() {
    const index = getIndex(survey, currentCategory);
    if (survey && index > 0) {
      navigate(`/analyse/${survey[index - 1].slug}`);
    } else {
      navigate(`/analyse`);
    }
  }

  function goForward() {
    const index = getIndex(survey, currentCategory);
    console.log(survey, currentCategory, index, survey[index + 1]);
    if (survey && index < survey.length - 1) {
      navigate(`/analyse/${survey[index + 1].slug}`);
    } else {
      navigate(`/report`);
    }
  }

  return <ProcessNavigation onNext={goForward} onPrevious={goBack} />;
}

export default AnalysisNavigation;

function getIndex(statements, currentCategory) {
  return (
    statements?.findIndex((category) => category.id === currentCategory?.id) ??
    -1
  );
}
