import { createSlice } from "@reduxjs/toolkit";
import { slugify } from "../components/Pages/Analysis/slugify";

const initialState = {
  currentService: null,
  services: [],
};

export const catalogSlice = createSlice({
  name: "catalog",
  initialState,
  reducers: {
    initCatalog: (state, action) => {
      const collator = new Intl.Collator("de");
      const services = [...action.payload]
        .sort((a, b) => collator.compare(a.title, b.title))
        .map((service) => ({
          ...service,
          slug: slugify(service.title),
        }));

      return {
        ...state,
        services,
      };
    },
    setCurrentService: (state, action) => {
      return {
        ...state,
        currentService: action.payload,
      };
    },
  },
});

export const { initCatalog, setCurrentService } = catalogSlice.actions;
export default catalogSlice.reducer;
