import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentService } from "../../../../state/catalogSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  answersSelector,
  catalogServicesSelector,
  surveySelector,
} from "../../../../state/selectors";
import Tile from "../../../Elements/Content/Tile";
import ServiceSectionContent from "../ServiceSectionContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import ReportFlower from "../../../Elements/Content/ReportFlower";
import AnalyzerService from "../../../../services/AnalyzerService";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import Button from "../../../Elements/Content/Button";
import clsx from "clsx";

const tableOfContents = new Map([
  ["challenges", "Herausforderungen"],
  ["use-case", "Anwendungsfall"],
  ["solution", "Lösung im Detail"],
  ["status-in-project", "Status im Projekt"],
  ["requirements", "Voraussetzungen"],
  ["availability", "Verfügbarkeit"],
  ["specification", "Spezifikation"],
]);

function ServiceCatalogDetail() {
  const { slug } = useParams();
  const survey = useSelector(surveySelector);
  const answers = useSelector(answersSelector);
  const services = useSelector(catalogServicesSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [service, setService] = useState();
  const [report, setReport] = useState(null);

  useEffect(() => {
    const index = services?.findIndex((service) => service.slug === slug);
    const service = services[index ?? 0];

    if (service) {
      setService(service);
      dispatch(setCurrentService(service));

      const category = survey.find((c) => c.module === service.module);
      if (category) {
        setReport(AnalyzerService.createReport(category, answers));
      }
    } else {
      navigate("/katalog");
    }
    //eslint-disable-next-line
  }, [services, slug]);

  if (service) {
    dispatch(setCurrentService(service));
  }

  const tableOfContentEntries = Array.from(tableOfContents.entries());

  if (!service) {
    return <></>;
  }

  return (
    <section className={"styles-detail"}>
      <header
        className={clsx(
          "styles-header",
          "grid lg:grid-cols-[1fr_min-content] lg:gap-[2rem]",
          "[&_ul]:my-1 [&_ul]:pl-[1.2rem]",
          "grid-cols-[1fr] gap-[2rem-mobile]"
        )}
      >
        <Tile
          className={{
            content: clsx(
              "styles-titleTileContent",
              'grid lg:mt-0 lg:gap-y-0 grid-cols-[min-content_1fr] lg:[grid-template-areas:"flower_category"_"flower_title"] content-end',
              'mt-8 gap-y-[1rem] [grid-template-areas:"flower_category"_"title_title"]'
            ),
          }}
          header={<Button onClick={() => navigate("/katalog")}>Zurück</Button>}
        >
          <ReportFlower
            className={clsx("styles-flower", "[grid-area:flower] mr-4")}
            module={service.module}
            result={report?.evaluation?.result ?? "MISSING_DATA"}
          />

          <p
            className={clsx(
              "styles-category",
              "[grid-area:category] mt-[0.2rem] mx-[0] mb-[0] text-[#3c424f] self-center"
            )}
          >
            KI-Service zu Modul {service.module} im Service-Lifecycle:{" "}
            {service.category}
          </p>
          <h2
            className={clsx(
              "styles-title",
              "[grid-area:title] m-0 text-[1.6rem] leading-[1.4]"
            )}
          >
            {service.title}
          </h2>
        </Tile>
        <Tile
          className={clsx("styles-toc min-w-[227px] mt-0")}
          header={<h3 className={"styles-tocHeader text-lg"}>Inhalt</h3>}
        >
          <ul className="!list-none m-0 !p-0 mt-4">
            {tableOfContentEntries.map(([key, title]) => (
              <li className="[&:last-child]:mb-0" key={key}>
                <a
                  className="no-underline hover:underline group flex items-center"
                  href={`#${key}`}
                >
                  <FontAwesomeIcon
                    className="mr-2 text-[0.8em] [transition:175ms_transform_ease-in] group-hover:translate-x-[0.2em]"
                    icon={faChevronRight}
                  />
                  {title}
                </a>
              </li>
            ))}
          </ul>
        </Tile>
      </header>
      <Tile>
        <aside
          className={clsx(
            "styles-contactList",
            "sm:float-right -mt-[1.2rem] sm:mt-0 px-[1.2rem] py-[1.5rem] sm:p-4 sm:ml-8 -mx-[1.5rem] sm:mr-0 mb-8 bg-[#d0d5da]"
          )}
        >
          <h4 className="mt-0 mb-4">Ansprechpartner*innen</h4>
          <ul className="!list-none m-0 p-0">
            {service.contacts.map((contact, index) => (
              <li
                key={index}
                className={clsx(
                  "styles-contact",
                  "text-[0.9rem] mb-4",
                  "[&:last-child]:mb-0"
                )}
              >
                {contact.name ? (
                  <span className={clsx("block", "styles-name", "font-bold")}>
                    {contact.name}
                  </span>
                ) : null}
                {contact.company ? (
                  <span className={clsx("block", "styles-company", "italic")}>
                    {contact.company}
                  </span>
                ) : null}
                {contact.email ? (
                  <a
                    className={clsx("styles-email")}
                    href={`mailto:${contact.email}`}
                  >
                    <FontAwesomeIcon icon={faEnvelope} /> {contact.email}
                  </a>
                ) : null}
              </li>
            ))}
          </ul>
        </aside>
        {service.sections.abstract
          ? service.sections.abstract.map((c, k) => (
              <div
                className={clsx("styles-abstract", "font-semibold text-base")}
                key={k}
              >
                <ServiceSectionContent section={c} />
              </div>
            ))
          : null}
        {service.sections.introduction
          ? service.sections.introduction.map((c, k) => (
              <ServiceSectionContent section={c} key={k} />
            ))
          : null}

        {tableOfContentEntries.map(([key, title]) => {
          const section = service.sections[key] ?? [];
          return (
            <div
              key={key}
              className={clsx(
                "styles-section",
                clsx("styles[key]"),
                key,
                key === "challenges" && "",
                key === "use-case" && "",
                key === "solution" && "",
                key === "status-in-project" && "",
                key === "requirements" && "",
                key === "availability" && "",
                key === "specification" &&
                  "[&_.styles-sectionContent]:-mb-[1.6rem] [&_.styles-sectionContent]:-ml-8 [&_.styles-sectionContent]:-mr-8"
              )}
            >
              <h3 className="mt-12 mb-4 text-lg">
                <a
                  className="no-underline cursor-default"
                  id={key}
                  href={`#${key}`}
                >
                  {title}
                </a>
              </h3>
              {section.map((content, index) => (
                <div key={index} className={clsx("styles-sectionContent", "")}>
                  <ServiceSectionContent section={content} />
                </div>
              ))}
            </div>
          );
        })}
      </Tile>
    </section>
  );
}

export default ServiceCatalogDetail;
