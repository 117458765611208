import React from "react";
import clsx from "clsx";

function AnalysisStatementHeader({ title }) {
  return (
    <header
      className={clsx(
        "styles.header",
        "gap-[0_3rem] items-center bg-[rgba(54,_123,_141,_0.2)] grid grid-flow-col grid-cols-[1fr_10rem_10rem] grid-rows-[1fr] pl-8 pr-8 [&_div]:text-center [&_div]:px-[0] [&_div]:py-4",
        !title && "styles-noTitle hidden"
      )}
    >
      {title ? <h2 className="my-5">{title}</h2> : <div></div>}
      <div className={clsx("styles-actualTarget hidden sm:block")}>
        Ist / Soll
      </div>
      <div className={clsx("styles-priority hidden sm:block")}>Wichtigkeit</div>
    </header>
  );
}

export default AnalysisStatementHeader;
