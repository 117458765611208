import React, { useEffect } from "react";
import Button from "../Button.jsx";
import youtubePreviewUrl from "../../../../utility/youtubePreviewUrl";
import { useSelector, useDispatch } from "react-redux";
import { allowYouTubeVideoSelector } from "../../../../state/selectors";
import { allowYouTubeVideo } from "../../../../state/consentSlice";
import clsx from "clsx";

function Video({ embedId }) {
  const allowYouTube = useSelector(allowYouTubeVideoSelector);
  const dispatch = useDispatch();
  const [allowVideo, setAllowVideo] = React.useState(allowYouTube);

  const alwaysAllow = () => {
    setAllowVideo(true);
    dispatch(allowYouTubeVideo());
  };

  useEffect(() => {
    setAllowVideo(allowYouTube);
  }, [allowYouTube]);

  return (
    <div
      className={clsx(
        "styles-video",
        "overflow-hidden pb-[80.25%] sm:pb-[56.25%] relative h-[0]",
        allowVideo &&
          "styles-disabled [&_img]:filter [&_img]:saturate-[10%] [&_img]:brightness-[15%] [&_.styles-hint]:block"
      )}
    >
      {!allowVideo ? (
        <div className={clsx("styles-hint", "")}>
          <div
            className={clsx(
              "styles-hintText",
              "absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 !text-[.8rem] sm:!text-[1.2rem] text-center w-full max-w-[30rem] text-[#fff] z-[1]"
            )}
          >
            <p>
              Mit dem Laden des Videos akzeptieren Sie die{" "}
              <a
                className="text-[#0090aa]"
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Datenschutzerklärung
              </a>{" "}
              von YouTube.
            </p>
            <div className={"styles-hintButtons"}>
              <Button
                className={clsx(
                  "styles-button",
                  "inline-block sm:block mx-[auto] my-1 sm:my-4"
                )}
                onClick={() => setAllowVideo(true)}
              >
                Video laden
              </Button>
              <Button
                className={clsx(
                  "styles-button",
                  "inline-block sm:block sm:mx-[auto] my-1 sm:my-4 sm:mt-16 ml-4"
                )}
                onClick={() => alwaysAllow()}
              >
                Videos immer laden
              </Button>
            </div>
            <p>
              Die Erlaubnis besteht bis zum Schließen der Seite bzw. des
              Browsers.
            </p>
          </div>
          <img
            className="left-[0] top-[0] h-full w-full absolute filter saturate-[10%] brightness-[15%]"
            src={youtubePreviewUrl(embedId)}
            alt=""
          />
        </div>
      ) : (
        <iframe
          className="left-[0] top-[0] h-full w-full absolute"
          width="853"
          height="480"
          src={`https://www.youtube-nocookie.com/embed/${embedId}?controls=2&rel=0&showinfo=0&playsinline=0&modestbranding=1&color=#0090aa`}
          frameBorder="0"
          allow={`accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture `}
          allowFullScreen
          title={`Embedded youtube `}
        />
      )}
    </div>
  );
}

export default Video;
