import React, { useEffect } from "react";
import { Outlet, useLocation, useMatch } from "react-router-dom";
import AppHeader from "../Elements/Layout/AppHeader";
import AppNavigation from "../Elements/Layout/AppNavigation/AppNavigation";
import AnalysisHeader from "../Pages/Analysis/AnalysisHeader/AnalysisHeader";

function Layout() {
  const location = useLocation();
  const match = useMatch("/analyse/*");

  useEffect(() => {
    // Scroll to top on route change, disable smooth scrolling
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [location]);

  return (
    <div className="relative pb-12">
      <AppHeader />
      <div className="sticky top-[0] left-[0] right-[0] [box-shadow:0_0_1rem_rgba(60,_66,_79,_0.5)] z-[6]">
        <AppNavigation />
        {match && <AnalysisHeader />}
      </div>
      <main className="mx-[auto] my-[0] max-w-[1200px] md:px-4 md:py-8 sticky md:static top-[0] left-[0] right-[0] [box-shadow:0_0_1rem_rgba(#3c424f,_0.5)] md:shadow-none">
        <Outlet />
      </main>
      <footer className="mt-8 text-center text-[0.8rem]">
        <ul className="m-0 p-0">
          <li className="inline">
            <a href="https://ladewig.gmbh/de/impressum" target=" _blank" rel="noreferrer">Impressum</a>
          </li>
          <li className="inline ml-4">
            <a href="https://itrk.legal/xB6.8U.s9z.html" target="_blank" rel="noreferrer">
              Datenschutzerklärung
            </a>
          </li>
        </ul>
      </footer>
    </div >
  );
}

export default Layout;
