import React, { useState } from "react";
import { Handles, Rail, Slider, Ticks, Tracks } from "react-compound-slider";
import clsx from "clsx";

const sliderStyle = {
  position: "relative",
  width: "100%",
};

function RangeSlider({ min, max, step, defaultValue, onChange, className }) {
  const values = [defaultValue ?? 0];
  const domain = [min, max];

  return (
    <div className={clsx("styles-rangeSlider", className, "w-full")}>
      <Slider
        mode={1}
        step={step}
        domain={domain}
        rootStyle={sliderStyle}
        onChange={onChange}
        values={values}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks count={4}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </div>
  );
}

export default RangeSlider;

function SliderRail({ getRailProps }) {
  return (
    <>
      <div
        className={clsx(
          'styles["rail-outside"] ',
          "absolute translate-x-[0%] -translate-y-1/2",
          "w-full h-[42px] rounded-[7px] cursor-pointer"
        )}
        {...getRailProps()}
      />
      <div
        className={clsx(
          'styles["rail-inside"]',
          "absolute translate-x-[0%] -translate-y-1/2 rounded-[7px] h-2",
          "[&_.track]:h-2 [&_.track]:rounded-[7px]",
          "w-full pointer-events-none bg-[#8e9dae]"
        )}
      />
    </>
  );
}

function Track({ source, target, getTrackProps, disabled = false }) {
  return (
    <div
      className={clsx(
        "styles-track",
        "absolute translate-x-[0%] -translate-y-1/2 bg-[#0090aa] cursor-pointer"
      )}
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

function Handle({
  domain: [min, max],
  handle: { id, value, percent },
  disabled = false,
  getHandleProps,
}) {
  const [hover, setHover] = useState(false);

  const onMouseEnter = () => {
    setHover(true);
  };

  const onMouseLeave = () => {
    setHover(false);
  };

  return (
    <>
      <div
        className={clsx(
          "styles-handle",
          "absolute -translate-x-1/2 -translate-y-1/2 w-6 h-[42px] cursor-pointer bg-transparent z-[5]"
        )}
        style={{
          left: `${percent}%`,
        }}
        {...getHandleProps(id, { onMouseEnter, onMouseLeave })}
      />
      <div
        className={clsx(
          'styles["handle-slider"]',
          "absolute -translate-x-1/2 -translate-y-1/2 w-[1.3rem] h-[1.3rem] rounded-[50%] bg-[#0090aa] cursor-pointer [transition:background-color_250ms_ease-in-out] z-[2]",
          disabled && "styles-disabled bg-[#596a7d]",
          hover && "styles-hover"
        )}
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
          left: `${percent}%`,
        }}
      />
    </>
  );
}

// *******************************************************
// TICK COMPONENT
// *******************************************************

export const Tick = ({ tick, count, format = (d) => d }) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: 14,
          width: 1,
          height: 5,
          backgroundColor: "rgb(200,200,200)",
          left: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: 22,
          fontSize: 10,
          textAlign: "center",
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
};
