import React from "react";
import clsx from "clsx";

function Table({ columnHeaders, rowHeaders, rawRows }) {
  const rows = convertRowsToMap(rawRows);

  return (
    <div className={"styles-tableWrapper"}>
      <DesktopTable
        columnHeaders={columnHeaders}
        rowHeaders={rowHeaders}
        rows={rows}
      />
      <MobileTable
        columnHeaders={columnHeaders}
        rowHeaders={rowHeaders}
        rows={rows}
      />
    </div>
  );
}

export default Table;

function convertRowsToMap(rows) {
  const rowsMapArray = Object.entries(rows).map(([colKey, cell]) => [
    colKey,
    new Map(Object.entries(cell)),
  ]);
  return new Map(rowsMapArray);
}

function DesktopTable({ columnHeaders, rowHeaders, rows }) {
  return (
    <table
      className={clsx(
        "styles-desktopTable",
        "w-full text-[0.9rem] hidden lg:table"
      )}
    >
      <thead>
        <tr>
          <th className="align-top text-left p-3"></th>
          {Array.from(columnHeaders.entries()).map(([colKey, title]) => (
            <th className="align-top text-left p-3" key={colKey}>
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {Array.from(rowHeaders.entries()).map(([rowKey, title]) => (
          <tr key={rowKey}>
            <th
              className="bg-[#0b90ce] text-[#fff] font-bold align-top text-left p-3"
              key={rowKey + "_title"}
            >
              {title}
            </th>
            {Array.from(columnHeaders.keys()).map((colKey) => {
              const content = rows.get(colKey)?.get(rowKey) ?? "";
              return (
                <td
                  className="bg-[#92d9f9] align-top text-left p-3"
                  key={rowKey + "_" + colKey}
                >
                  {content}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function MobileTable({ columnHeaders, rowHeaders, rows }) {
  return (
    <div className={clsx("styles-mobileTable", "lg:hidden")}>
      {Array.from(rows.entries()).map(([colKey, row]) => (
        <details key={colKey}>
          <summary>{columnHeaders.get(colKey)}</summary>
          <div>
            <dl>
              {Array.from(row.entries()).map(([rowKey, content], index) => (
                <div
                  key={index}
                  className={"appear"}
                  style={{ ["--appear-order"]: index + 1 }}
                >
                  <dt>{rowHeaders.get(rowKey)}</dt>
                  <dd>{content}</dd>
                </div>
              ))}
            </dl>
          </div>
        </details>
      ))}
    </div>
  );
}
