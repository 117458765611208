import { createSelector } from "@reduxjs/toolkit";

export const rootSelector = (state) => state;
export const surveySelector = createSelector(
  [rootSelector],
  (state) => state.analysis.survey
);
export const answersSelector = createSelector(
  [rootSelector],
  (state) => state.analysis.answers
);
export const progressSelector = createSelector(
  [rootSelector],
  (state) => state.analysis.progress
);
export const createProgressSelector = (categoryId) =>
  createSelector([progressSelector], (progress) => {
    if (categoryId) {
      return progress.byCategory[categoryId] ?? 0;
    }

    const percentValues = Object.values(progress.byCategory);
    const percent = percentValues.length
      ? percentValues.reduce((a, b) => a + b, 0) / percentValues.length
      : 0;

    return Math.ceil(percent);
  });

export const catalogSelector = createSelector(
  [rootSelector],
  (state) => state.catalog
);
export const catalogServicesSelector = createSelector(
  [catalogSelector],
  (catalog) => catalog.services
);
export const currentServiceSelector = createSelector(
  [catalogSelector],
  (catalog) => catalog.currentService
);
export const createServiceByCategorySelector = (category) =>
  createSelector([catalogServicesSelector], (services) =>
    services.filter((document) => document.module === category.module)
  );

export const createServiceBySlugSelector = (slug) =>
  createSelector([catalogServicesSelector], (services) =>
    services.find((document) => document.slug === slug)
  );

export const consentSelector = createSelector(
  [rootSelector],
  (state) => state.consent
);
export const allowYouTubeVideoSelector = createSelector(
  [consentSelector],
  (consent) => consent.youtubeVideo
);
